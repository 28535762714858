import {
  Add20Regular,
  ArrowSync20Regular,
  ArrowUpRight20Regular,
  Bookmark20Regular,
  ClipboardTextLtr20Regular,
  DatabaseMultiple20Regular,
  DataPie20Regular,
  DocumentAdd20Regular,
  DocumentText20Regular,
  FolderLink20Regular,
  Link20Regular,
  Payment20Regular,
  PeopleTeam20Regular,
  // PersonAdd20Regular,
  Settings20Regular,
} from "@fluentui/react-icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppConfig from "app-config";
import ContextualMenuV9, {
  MenuGroups,
} from "components/contextualMenu/ContextualMenuV9";
import { NotificationIcon } from "components/icon";
import Modal from "components/modal";
import { fetchDataForNewBusinessOpportunity } from "helpers/BusinessOpportinityHelper";
import { useTranslation } from "hooks";
import {
  isUserTeamOwner,
  // isAllowedToGetTemporaryAccess,
} from "libs/customer-settings-helpers";
import { renderIcon } from "libs/render-icon";
import { Customer } from "models/customer";
import { CustomerPageDetailedPageEnum } from "models/enums/CustomerPageDetailedPage.enum";
import { User, UserRoles } from "models/user";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import { syncCustomerActivities } from "state/activities/syncActivities.state";
import { setOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import { fetchServiceLinesCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import {
  clearCustomerCache,
  createCustomerTeam,
  fetchTeamsFilesTabLink,
} from "state/customers/actions";
import { appendToastMessage } from "state/notifications";
import { useAppDispatch, useAppSelector } from "state/use-app-redux";
import CustomerPageServiceNotesModal from "views/activities/customerPage/customerPageServiceNotes/customerPageServiceNotesModal";
import { CapegoFortnoxConnectionModal } from "views/activities/overview/modals/CapegoFortnoxConnectionModal";
import CustomerOverviewReportsLink from "views/activities/overview/modals/CustomerOverviewReportsLink";
import { PostponePaymentForInvoiceModal } from "views/activities/overview/modals/PostponePaymentForInvoiceModal";

import ReportsModal from "views/activities/overview/modals/ReportsModal";
// import { TemporaryAccessModal } from "views/activities/overview/modals/TemporaryAccessModal";
import { SIEFilesModal } from "views/activities/overview/modals/SIEFilesModal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";

const CBModalState = {
  TEAM_MODAL: "TEAM_MODAL",
  SERVICE_MODAL: "SERVICE_MODAL",
  CREATE_TEAM_MODAL: "CREATE_TEAM_MODAL",
  CAPEGO_FORNOX_MODAL: "CAPEGO_FORNOX_MODAL",
  SIE_FILES: "SIE_FILES",
  REPORTS: "REPORTS",
  DEFERRED_PAYMENT: "DEFERRED_PAYMENT",
  TEMPORARY_ACCESS: "TEMPORARY_ACCESS",
};

interface InitialState {
  modalState: string | null;
  isLoading: boolean;
  creatingTeamLoading: boolean;
}

const initialState: InitialState = {
  modalState: null,
  isLoading: false,
  creatingTeamLoading: false,
};

type Props = {
  customer: Customer;
};

const MACONOMY_URL = `${AppConfig.MACONOMY_URL}`;

export default function CustomerDetailsMenu({ customer }: Props) {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const hasAccessToSettings = isUserTeamOwner(customer, currentUser);
  const customerState = useAppSelector((state) => state.customers).data.find(
    (c) => c.customer.customer_number === customer.customer_number
  );
  const [state, setState] = useState(initialState);
  const maconomyLink = `${MACONOMY_URL}/workspace/customers;CustomerNumber=${customer.customer_number}`;

  const openModal = (modal: string) => {
    setState({
      ...initialState,
      modalState: modal,
    });
  };

  const closeModal = () => {
    setState(initialState);
  };

  // https://lrfkonsult.atlassian.net/browse/ML-932
  // Ticket is still in progress, BE fixes pending
  // uncomment when done
  // const shouldGetTemporaryAccess = isAllowedToGetTemporaryAccess(
  //   customer,
  //   currentUser
  // );

  function isIconApplicable(): boolean {
    if (customerState?.customer?.deliveryPlan !== undefined) {
      if (customerState.customer.deliveryPlan.length === 0) {
        return true;
      }
      const currentYear = new Date().getFullYear();
      const anyDeliveryLockedIn = customerState.customer.deliveryPlan.some(
        (d) => {
          if (d.start_date?.getFullYear() === currentYear && d.is_locked_in) {
            return true;
          }
          return false;
        }
      );
      return !anyDeliveryLockedIn;
    }
    return false;
  }

  const enterNewDealFlowForCustomer = async () => {
    const customerData = await fetchDataForNewBusinessOpportunity(
      customer,
      dispatch,
      navigate
    );
    if (customerData?.status === "error") {
      dispatch(appendToastMessage("SOMETHING_WENT_WRONG", "error"));
    }
  };

  const onClickAddServiceNote = () => {
    dispatch(fetchServiceLinesCallerWrapper());
    dispatch(setOpenCreateModalFor(CustomerPageDetailedPageEnum.SERVICE_NOTES));
    openModal(CBModalState.SERVICE_MODAL);
  };

  const isDeferredPaymentAllowed = (current: User) => {
    if (current?.roles?.includes(UserRoles.FEATURES_DEFERRED_PAYMENT)) {
      return true;
    }

    return (
      current?.azureGroups?.some(
        (azureGroup) =>
          azureGroup.displayName === AppConfig.USER_ROLES.NYK_MANAGER
      ) || false
    );
  };

  const deferredPaymentAllowed = isDeferredPaymentAllowed(currentUser);

  const getCustomerDetailsMenuItems = (): MenuGroups[] => {
    const settingsItems = hasAccessToSettings
      ? [
          {
            text: `${translate("CUSTOMER_SETTINGS")}`,
            onClick: () =>
              navigate({
                pathname: AppRouteHelper.getCustomerSettings(
                  customer.customer_number
                ),
              }),
            icon: () =>
              renderIcon(
                <div className="position-relative">
                  {isIconApplicable() && <NotificationIcon />}
                  <Settings20Regular
                    onClick={() =>
                      navigate({
                        pathname: AppRouteHelper.getCustomerSettings(
                          customer.customer_number
                        ),
                      })
                    }
                  />
                </div>
              ),
            hasDivider: true,
          },
        ]
      : [];

    const renderReportsLink = () => {
      if (customer.apps?.datalagret?.status === "offline") {
        return <CustomerOverviewReportsLink content="DATA_STORE_NO_RESPONSE" />;
      }

      if (!customer.customer_key) {
        return (
          <CustomerOverviewReportsLink content="MISSING_CUSTOMER_IN_DATALAGRET" />
        );
      }
      return (
        <CustomerOverviewReportsLink
          content="VIEW_DATALAGRET_REPORTS"
          onClick={() => openModal(CBModalState.REPORTS)}
        />
      );
    };

    const onGoToTeamsFolderClick = async () => {
      let url = customer.teamsFilesTabLink;
      if (!url) {
        url = await dispatch(fetchTeamsFilesTabLink(customer));
      }
      if (url) {
        window.open(url.replaceAll('"', ""), "_blank");
      }
    };

    const customerTeamsFolderLinkItems = customer.teamsStatus
      ? [
          {
            text: `${translate("GO_TO_TEAMS_FILES")}`,
            onClick: onGoToTeamsFolderClick,
            icon: () => renderIcon(<FolderLink20Regular />),
          },
        ]
      : [];

    const actionItems = [
      {
        title: translate("ACTIONS_SECTION"),
        className: "text-color-blue",
        groupItems: [
          {
            text: `${translate("NEW_CUSTOMER_BUSINESS_OPPORTUNITY")}`,
            onClick: enterNewDealFlowForCustomer,
            icon: () => renderIcon(<DocumentAdd20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("ADD_INDIVIDUAL_ACTIVITITES")}`,
            icon: () => renderIcon(<Add20Regular />),
            onClick: () =>
              navigate(
                AppRouteHelper.getAssignmentsEdit(customer.customer_number)
              ),
            hidden: !hasAccessToSettings,
            hasDivider: hasAccessToSettings,
          },
          {
            text: `${translate("ADD_NEW_SERVICE_NOTE")}`,
            icon: () => renderIcon(<Add20Regular />),
            onClick: () => {
              onClickAddServiceNote();
            },
            hidden: !hasAccessToSettings,
            hasDivider: hasAccessToSettings,
          },
          {
            text: `${translate("SYNC_CUSTOMER_ACTIVITIES")}`,
            onClick: () => {
              dispatch(syncCustomerActivities(customer.customer_number));
            },
            icon: () => renderIcon(<ArrowSync20Regular />),
            hidden: !hasAccessToSettings,
            hasDivider: hasAccessToSettings,
          },
          {
            text: `${translate(
              customer.teamsStatus ? "SYNC" : "CREATE_NEW_TEAM"
            )}`,
            onClick: () => openModal(CBModalState.CREATE_TEAM_MODAL),
            icon: () => renderIcon(<PeopleTeam20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("CUSTOMER_ACTIONS.CLEAR_CACHE")}`,
            onClick: () =>
              dispatch(clearCustomerCache(customer.customer_number)),
            icon: () => renderIcon(<DatabaseMultiple20Regular />),
            hasDivider: currentUser.roles?.includes(UserRoles.USERS_DEVELOPER),
            hidden: !currentUser.roles?.includes(UserRoles.USERS_DEVELOPER),
          },
          // https://lrfkonsult.atlassian.net/browse/ML-932
          // Ticket is still in progress, BE fixes pending
          // uncomment when done
          // {
          //   text: `${translate(
          //     "CUSTOMER_DETAILS_MENU.GET_TEMPORARY_ACCESS_TO_CUSTOMER"
          //   )}`,
          //   onClick: () => openModal(CBModalState.TEMPORARY_ACCESS),
          //   icon: () => renderIcon(<PersonAdd20Regular />),
          //   hidden: !shouldGetTemporaryAccess,
          //   hasDivider: shouldGetTemporaryAccess,
          // },
          {
            text: `${translate("CAPEGOFORTNOX_MANAGE_CONNECTION")}`,
            onClick: () => openModal(CBModalState.CAPEGO_FORNOX_MODAL),
            icon: () => renderIcon(<Link20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("POSTPONE_INVOICE_PAYMENT")}`,
            onClick: () => openModal(CBModalState.DEFERRED_PAYMENT),
            icon: () => renderIcon(<Payment20Regular />),
            hidden: !deferredPaymentAllowed,
          },
        ],
      },
    ];

    const listItems = [
      {
        title: translate("LINKS_SECTION"),
        className: "text-color-blue",
        groupItems: [
          ...settingsItems,
          {
            text: `${translate("CUSTOMER_PAGE")}`,
            onClick: () =>
              navigate({
                pathname: AppRouteHelper.getCustomerPage(
                  customer.customer_number
                ),
              }),
            icon: () =>
              renderIcon(
                <div className="position-relative">
                  {isIconApplicable() && <NotificationIcon />}
                  <Bookmark20Regular
                    onClick={() =>
                      navigate({
                        pathname: AppRouteHelper.getCustomerSettings(
                          customer.customer_number
                        ),
                      })
                    }
                  />
                </div>
              ),
            hasDivider: true,
          },
          {
            text: `${translate("DELIVERY_PLANNER")}`,
            onClick: () =>
              navigate(
                AppRouteHelper.getCustomerDeliveryPlanner(
                  customer.customer_number
                )
              ),
            icon: () =>
              renderIcon(
                <div className="position-relative">
                  {isIconApplicable() && <NotificationIcon />}
                  <DataPie20Regular />
                </div>
              ),
            hasDivider: true,
          },
          {
            text: "Maconomy",
            onClick: () => {
              window.open(maconomyLink);
            },
            icon: () => renderIcon(<ArrowUpRight20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("CUSTOMER_DETAILS.PROJECTS")}`,
            onClick: () => {
              navigate({
                pathname: AppRouteHelper.getCustomerDetails(
                  customer.customer_number
                ),
              });
            },
            icon: () => renderIcon(<ClipboardTextLtr20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("CUSTOMER_ACTIONS.REPORTS")}`,
            onClick: () => openModal(CBModalState.REPORTS),
            icon: () => renderReportsLink(),
            hasDivider: true,
          },
          {
            text: `${translate("CUSTOMER_ACTIONS.SIE_FILES")}`,
            onClick: () => openModal(CBModalState.SIE_FILES),
            icon: () => renderIcon(<DocumentText20Regular />),
            hasDivider: customer.teamsStatus,
          },

          ...customerTeamsFolderLinkItems,
        ],
      },
      ...actionItems,
    ];
    return listItems;
  };

  const createOrSyncTeam = (selectedCustomer: Customer) => {
    setState({
      ...initialState,
      creatingTeamLoading: true,
    });
    dispatch(createCustomerTeam(selectedCustomer)).finally(() => {
      setState({
        ...initialState,
        creatingTeamLoading: false,
      });
      closeModal();
    });
  };

  return (
    <>
      <ContextualMenuV9
        menuGroups={getCustomerDetailsMenuItems()}
        positioning="after-bottom"
        closeOnScroll
      />
      <CustomerPageServiceNotesModal
        customerId={customer.customer_number}
        openModal={state.modalState === CBModalState.SERVICE_MODAL}
        setOpenModal={(isOpen: boolean) =>
          isOpen ? openModal(CBModalState.SERVICE_MODAL) : closeModal()
        }
        createdFromCB
      />
      <Modal
        size="medium"
        isOpen={state.modalState === CBModalState.CREATE_TEAM_MODAL}
        onDismiss={() => !state.creatingTeamLoading && closeModal()}
        header={
          <ModalHeader
            headerTitleText={customer?.teamsStatus ? "SYNC" : "CREATE_NEW_TEAM"}
          />
        }
        footer={
          <ModalFooter
            labelCancel="CANCEL"
            labelSubmit={customer?.teamsStatus ? "SYNC" : "CREATE_NEW_TEAM"}
            onCancel={closeModal}
            onSave={() => createOrSyncTeam(customer)}
            isDisabled={state.creatingTeamLoading}
            isLoading={state.creatingTeamLoading}
          />
        }
      >
        {!customer?.teamsStatus
          ? `${translate("A_TEAM_FOR_CUSTOMER")} "${
              customer?.name
            }" ${translate("TEAM_NOT_EXIST_CREATE")}`
          : `${translate("TEAMS_EXISTS_FOR_CUSTOMER")} "${
              customer?.name
            }". ${translate("TEAM_EXISTS_SYNC")}`}
      </Modal>
      {state.modalState === CBModalState.CAPEGO_FORNOX_MODAL && (
        <CapegoFortnoxConnectionModal
          isOpen
          orgNumber={customer?.company_registration_number || ""}
          onDismiss={closeModal}
        />
      )}
      {state.modalState === CBModalState.SIE_FILES && (
        <SIEFilesModal
          selectedCustomer={customer}
          showSIEFilesModal
          onDismiss={closeModal}
        />
      )}
      {state.modalState === CBModalState.REPORTS && (
        <ReportsModal
          selectedCustomer={customer}
          showReportsModal
          onDismiss={closeModal}
        />
      )}
      {state.modalState === CBModalState.DEFERRED_PAYMENT && (
        <PostponePaymentForInvoiceModal
          isOpen
          customerNumber={customer?.customer_number || ""}
          onDismiss={closeModal}
        />
      )}

      {/* https://lrfkonsult.atlassian.net/browse/ML-932
          Ticket is still in progress, BE fixes pending
          uncomment when done */}
      {/* {state.modalState === CBModalState.TEMPORARY_ACCESS && (
        <TemporaryAccessModal
          isOpen
          customer={customer}
          onDismiss={closeModal}
        />
      )} */}
    </>
  );
}

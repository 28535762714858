import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AppRouteHelper, CustomerDetailsParams, ServicesParams } from "routes";
import { RootState } from "state";
import { fetchActivitiesServicesForCustomers } from "state/activities/actions";
import {
  CREATED_USING_DP,
  GENERAL_ACCOUNTING_DELIVERY,
  YEAR_END_DELIVERY,
  createOrUpdateYearlyRecurringDeliveries,
  deleteCustomerDeliveriesBatch,
} from "state/customers/actions";
import { appendToastMessage } from "state/notifications";
import { useTranslation } from "hooks/use-translate";
import { Customer } from "models/customer";
import {
  CustomerDelivery,
  CustomerPackage,
  DeliveryRecurrence,
} from "models/deliveryPlan";
import { Button } from "components/button";
import { LayoutBody, LayoutHeader } from "components/layout/Layout";
import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { GeneralAccountingPackageConfiguration } from "./GeneralAccountingPackageConfiguration";
import { YearEndPackageConfiguration } from "./YearEndPackageConfiguration";

export type DeliveryChangeProps = {
  customer: Customer;
  existingDeliveries: CustomerDelivery[];
  preSelectedStartDate?: Date;
  onChange: (
    packages: CustomerPackage[],
    recurrence: DeliveryRecurrence,
    deliveryDate: Date,
    startRecurringDate?: Date
  ) => void;
  onRemoveAll?: (date: Date) => void;
  loadingDeliveries?: boolean;
};

type PackageConfigurationComponent = (
  props: DeliveryChangeProps
) => JSX.Element;

export const DeliveryServiceConfigurationForms = new Map<
  string,
  PackageConfigurationComponent
>([
  [YEAR_END_DELIVERY, YearEndPackageConfiguration],
  [GENERAL_ACCOUNTING_DELIVERY, GeneralAccountingPackageConfiguration],
]);

export function CustomerDeliveryPlannerConfiguration() {
  const { customerId, serviceId } = useParams<
    CustomerDetailsParams & ServicesParams
  >();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingDeliveries, setLoadingDeliveries] = useState(false);
  const customers = useSelector((state: RootState) => state.customers);
  const availableServices = useSelector(
    (state: RootState) => state.activities.availableDeliveryServices
  );
  const customerState = customers.data.find(
    (c) => c.customer.customer_number === customerId
  );

  if (!customerState) {
    return <p>Missing customer</p>;
  }

  const { customer } = customerState;

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () => navigate(AppRouteHelper.getAssignments()),
    },
    {
      text: customer ? customer.name : "...",
      onClick: () => navigate(AppRouteHelper.getAssignments(customerId)),
    },
    {
      text: translate("DELIVERY_PLANNER"),
      onClick: () =>
        customer &&
        navigate(
          AppRouteHelper.getCustomerDeliveryPlanner(customer.customer_number)
        ),
    },
    {
      text: translate("FILL_IN_INFORMATION"),
    },
  ];

  if (!serviceId) {
    return <p>Missing service ID</p>;
  }

  const { deliveryPlan } = customer;

  if (!deliveryPlan.length) {
    return (
      <>
        <p>Empty delivery plan</p>
        <Button onClick={() => navigate(-1)}>Tillbaka</Button>
      </>
    );
  }

  const delivery = deliveryPlan.find((dp) => dp.uuid === serviceId);
  if (!delivery) {
    return <p>Missing delivery plan entry {serviceId}</p>;
  }

  const definition = availableServices.data.find(
    (a) => a.delivery_name === delivery.delivery_name
  );
  if (!definition) {
    return <p>Missing delivery service definition</p>;
  }

  const DeliveryConfigurationForm = DeliveryServiceConfigurationForms.get(
    definition.delivery_name
  );

  if (!DeliveryConfigurationForm) {
    return <p>Missing configuration form for {definition.delivery_name}</p>;
  }

  const deleteDeliveries = async (endDate: Date) => {
    if (!customerState) {
      return;
    }

    const existingDeliveries = customerState.customer.deliveryPlan.filter(
      (dp) => dp.delivery_name === definition.delivery_name
    );

    setLoadingDeliveries(true);
    await dispatch(
      deleteCustomerDeliveriesBatch({
        customer: customerState.customer,
        deliveryIds: existingDeliveries.map((d) => d.uuid),
        endDate,
      })
    );
    await dispatch(fetchActivitiesServicesForCustomers({}));
    setLoadingDeliveries(false);
    dispatch(appendToastMessage("OFFBOARDED_PACKAGES", "success"));
  };

  return (
    <>
      <LayoutHeader>
        <span />
      </LayoutHeader>
      <LayoutBody>
        <BreadcrumbV9 items={breadcrumbs} />
        <DeliveryConfigurationForm
          customer={customer}
          existingDeliveries={deliveryPlan.filter(
            (p) => p.delivery_name === delivery.delivery_name
          )}
          onChange={(packages, recurrence, deliveryDate, startRecuringDate) => {
            dispatch(
              createOrUpdateYearlyRecurringDeliveries({
                customer,
                packages,
                startYearDate: startRecuringDate || new Date(),
                yearlyDeliveryDate: startRecuringDate || new Date(),
                recurrence,
                deliveryType: delivery.delivery_name,
                deliveryDates: recurrence === "MONTHLY" ? [] : [deliveryDate],
                createdUsing: CREATED_USING_DP,
                lock_in: delivery.is_locked_in,
              })
            );
            navigate(-1);
          }}
          onRemoveAll={(endDate) => {
            deleteDeliveries(endDate);
          }}
          loadingDeliveries={loadingDeliveries}
        />
      </LayoutBody>
    </>
  );
}
